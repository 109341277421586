#__tealiumGDPRecModal {
  .privacy_prompt_content1 {
    font-size: 12px !important;
  }
  .privacy_prompt_container1 {
    overflow-y: unset !important;
    width: 100vw;
  }
  .privacy_prompt1 {
    padding: 10px 1% !important;
    @media only screen and (max-width: 768px) {
      padding: 16px !important;
    }
  }
  .privacy_prompt_container1 .__button {
    border-radius: 50vmax;
    font-size: 12px !important;
    height: 40px;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
  .privacy_prompt_container1 {
    font-family: NeueHaas, 'Helvetica Neue', Arial, sans-serif !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .privacy_prompt_content21 {
    width: 25% !important;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }

  .privacy_prompt_content1 {
    width: 70% !important;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }

  .privacy_prompt_content2 {
    width: 221px !important;
    height: 40px !important;
    flex-grow: 0;
    margin: 39px 16px 8px 123px;
    border-radius: 20px;
    font-size: 12px !important;
    background-color: #ebe9e4 !important;
    padding: 0 34.7px 0 34.7px !important;
    border: 0.4px solid #333 !important;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: 0.7rem !important;
    }
  }

  #consent_prompt_pref {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100% !important;
    margin-top: -2.7rem !important;
    cursor: default !important;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: 0.4rem !important;
    }
  }

  #consent_prompt_pref a {
    text-decoration: none !important;
    cursor: pointer;
  }

  a.privacy_prompt_content2 {
    display: block;
    width: 221px !important;
    font-size: 12px !important;
    color: #000 !important;
    font-weight: 100 !important;
    margin-top: 39px;
    margin-right: 15px;
    float: right;
    background-color: #ebe9e4 !important;
    padding: 0.68rem 0 !important;
    border: 0.4px solid #333 !important;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: -0.6rem !important;
    }
  }

  #consent_prompt_submitNo {
    position: relative;
    z-index: 1;
  }
}

.lazy-load-wrapper {
  height: 100%;
  width: 100%;
  max-width: 100vw;

  img {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    object-fit: cover;
  }
}

#rxcApp {
  .PrescriptionModal__container__description {
    padding: 0 20px 0 20px;
  }

  @media only screen and (min-width: 1200px) {
    .PrescriptionModal__container {
      overflow-y: auto;
      max-height: 90vh;
    }

    .PrescriptionModal__container__description {
      padding: 0 64px 40px;
    }
  }
}
